import { createApp } from 'vue'
import App from './App.vue'
import 'vant/lib/index.css';
import router from './router/router.js'
import '@vant/touch-emulator'
import 'normalize.css'
import '@/assets/css/index.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
createApp(App)
    .use(router)
    .mount('#app')
