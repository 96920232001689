import {createRouter,createWebHashHistory} from 'vue-router'

const router=createRouter({
    history:createWebHashHistory(),
    routes:[
        {path: '/', redirect: 'mul'},
        {
            path:"/mul",
            component:()=>import (/* webpackChunkName:'mul'*/'/src/components/MulTraining'),
        },
        {
            path:"/add",
            component:()=>import (/* webpackChunkName:'add'*/'/src/components/AddTraining'),
        },
        {
            path:"/sub",
            component:()=>import (/* webpackChunkName:'sub'*/'/src/components/SubTraining'),
        },
        {
            path:"/div",
            component:()=>import (/* webpackChunkName:'div'*/'/src/components/DivTraining'),
        }

    ]
});

export default router;